



















































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ISettings} from '@/store/modules/Settings';

const shipmentModule = namespace('shipment');
const settingsModule = namespace('settings');

@Component
export default class Home extends Vue {

  @settingsModule.Getter('settings')
  private settings!: ISettings;

  @shipmentModule.Action('reset')
  private resetShipment!: () => void;

  onPickup() {
    this.$router.push({name: 'CellChooser'});
  }

  onDelivery() {
    this.$router.push({name: 'CellChooser'});
  }
  created(): void {
    this.resetShipment();
  }
}
