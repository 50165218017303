































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import AppFooter from '@/components/AppFooter.vue';
import Loading from '@/components/Loading.vue';
import LanguageChooser from '@/components/LanguageChooser.vue';
import Help from '@/components/Help/Help.vue';
import ErrorDialog from '@/components/ErrorDialog.vue';
import {ISettings} from '@/store/modules/Settings';
import {appVersion} from '@/helpers/env';

const settingsModule = namespace('settings');

@Component({
  components: {
    AppFooter,
    LanguageChooser,
    Loading,
    Help,
    ErrorDialog,
  },
})
export default class App extends Vue {
  @settingsModule.Getter('settings')
  private settings!: ISettings;

  private version: string = appVersion;

  mounted(): void {
    if (!this.settings.hubId) {
      this.$router.push({ name: 'Home'});
    }
  }
}
