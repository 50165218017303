import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import CellChooser from '@/views/CellChooser.vue';
import DeliveryCode from '@/views/DeliveryCode.vue';
import DeliveryDone from '@/views/DeliveryDone.vue';
import Test from '@/views/Test.vue';
import Entrance from '@/components/Entrance.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/hub/:id',
    name: 'Entrance',
    component: Entrance,
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/cellSize',
    name: 'CellChooser',
    component: CellChooser,
  },
  {
    path: '/deliveryCode',
    name: 'DeliveryCode',
    component: DeliveryCode,
  },
  {
    path: '/deliveryDone',
    name: 'DeliveryDone',
    component: DeliveryDone,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
