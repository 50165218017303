


























import {Component, Vue} from 'vue-property-decorator';
import {uiBus} from '@/plugins/uiBus';


@Component
export default class Test extends Vue {
  goHome() {
    this.$router.push({ name: 'Home'});
  }
  onLoadingTest() {
    uiBus.emit('backend.call.begin');
    setTimeout(() => {
      uiBus.emit('backend.call.KO');
    },3000);
  }

}
