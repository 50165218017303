import Vue from 'vue';
import { i18n } from '@/services/translations';
import '@/assets/scss/app.scss';
import router from '@/router';
import store from '@/store';
import App from './App.vue';
import Ripple from 'vue-material-design-ripple';
import {IOnOffHandlers} from '@/models/IOnOffHandlers';
import {uiBus} from '@/plugins/uiBus';
import {EventEmitter} from 'events';

// Directive for button click effect
Vue.directive('ripple', Ripple);

// Vue prototype injection
declare module 'vue/types/vue' {
  interface Vue {
    $uiBus: EventEmitter;
    $ons: (o: IOnOffHandlers) => IOnOffHandlers;
    $offs: (o: IOnOffHandlers) => IOnOffHandlers;
  }
}

Vue.prototype.$uiBus = uiBus;

Vue.prototype.$ons = (ons: IOnOffHandlers) => {
  Object.keys(ons)
      .forEach(eachEvent => uiBus.on(eachEvent, ons[eachEvent]));
  return ons;
};

Vue.prototype.$offs = (offs: IOnOffHandlers) => {
  Object.keys(offs)
      .forEach(eachEvent => uiBus.off(eachEvent, offs[eachEvent]));
};

// No production tip
Vue.config.productionTip = false;

// Create App
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
