









import {Vue, Component} from 'vue-property-decorator';
import {IOnOffHandlers} from '@/models/IOnOffHandlers';
import {IErrors} from '@/models/IErrors';
import Dialog from '@/components/Dialog.vue';

@Component({
  components: {
    Dialog,
  }
})
export default class ErrorDialog extends Vue {

  private showErrorDialog = false;
  private errors: IErrors = {};
  protected subscribes: IOnOffHandlers = {};

  get messages(): string[] {
    const messages: string[] = [];
    Object.keys(this.errors).forEach(key => {
      this.errors[key].forEach(message => {
        messages.push(message);
      })
    });
    return messages;
  }

  created(): void {
    this.subscribes = this.$ons({
      'dialog:open': this.onOpen,
      'dialog:close': this.onClose,
    });
  }

  onClose() {
    this.showErrorDialog = false;
  }

  onOpen(payload: any = null) {
    this.errors = payload;
    this.showErrorDialog = true;
  }

}
