
































import { Vue, Component } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Keyboard from '@/components/Keyboard.vue';
import NavModule from '@/components/NavModule.vue';
import {uiBus} from '@/plugins/uiBus';
import {IOnOffHandlers} from '@/models/IOnOffHandlers';
import {IErrors} from '@/models/IErrors';
import {ISettings} from '@/store/modules/Settings';

const shipmentModule = namespace('shipment');
const settingsModule = namespace('settings');

@Component({
  components: {
    Keyboard,
    NavModule,
  },
})
export default class DeliveryCode extends Vue {

  // Data
  private code = '';
  private codeMaxLength = 6;
  protected subscribes: IOnOffHandlers = {};

  // Store
  @shipmentModule.Action('setHubId')
  private setHubId!: (id: string) => void;

  @shipmentModule.Action('setDeliveryCode')
  private setDeliveryCode!: (code: string) => void;

  @shipmentModule.Action('createShipment')
  private createShipment!: () => void;

  @shipmentModule.Getter('errors')
  private errors!: IErrors;

  @settingsModule.Getter('settings')
  private settings!: ISettings;

  // Lifecycle
  created(): void {
    this.subscribes = this.$ons({
      'backend.call.OK.shipmentSent': this.nextScreen,
      'backend.call.KO.shipmentSent': this.showErrors,
    });
  }

  // Methods
  updateCode(key: string) {
    if (this.code.length < this.codeMaxLength) {
      this.code += key;
    }
  }
  onBackSpace() {
    this.code = this.code.substr(0, this.code.length - 1);
  }
  onBack() {
    this.$router.push({ name: 'CellChooser'});
  }
  onNext() {
    this.setHubId(this.settings.hubId);
    this.setDeliveryCode(this.code);
    this.createShipment();
  }
  nextScreen() {
    this.$router.push({ name: 'DeliveryDone'});
  }
  showErrors() {
    uiBus.emit('dialog:open', this.errors);
  }
}

