































import { Vue, Component } from 'vue-property-decorator';
import {uiBus} from '@/plugins/uiBus';

@Component({
  name: 'DeliveryDone',
})
export default class DeliveryDone extends Vue {
  onFinish() {
    this.$router.push({ name: 'Home'});
  }
  onRetry() {
    uiBus.emit('backend.call.begin');
    setTimeout(() => {
      uiBus.emit('backend.call.KO');
    }, 2000);
  }
}
