// This is an experimental feature to autofill help module

import {Route} from 'vue-router';

export interface HelpNavItemInterface {
  id: number;
  translationGroup: string;
  parent: number;
}

export interface HelpNodeInterface {
  id: number;
  translationGroup: string;
  parent: number;
  children: HelpNodeInterface[];
}

const helpNavItems = [
  {id: 1, translationGroup: 'help.home', parent: 0},
  {id: 2, translationGroup: 'help.delivery', parent: 1},
  {id: 3, translationGroup: 'help.cellChooser', parent: 2},
  {id: 4, translationGroup: 'help.deliveryCode', parent: 2},
  {id: 5, translationGroup: 'help.deliveryDone', parent: 2},
  {id: 6, translationGroup: 'help.pickup', parent: 0},
];

const enum helpNodes {
  home = 1,
  cellChooser = 3,
  deliveryCode = 4,
  deliveryDone = 5,
  pickup = 6,
}

const unFlatten = (items: HelpNavItemInterface[]) => {
  const tree = [], mappedArr: any = {};
  // Build a hash table and map items to objects
  items.forEach((item) => {
    const id = item.id;
    if (!mappedArr.hasOwnProperty(id)) {
      mappedArr[id] = item;
      mappedArr[id].children = [];
    }
  });
  // Loop over hash table
  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      const mappedElem = mappedArr[id];
      if (mappedElem.parent) {
        const parentId = mappedElem.parent;
        mappedArr[parentId].children.push(mappedElem);
      }
      // If the element is at the root level, directly push to the tree
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};

export const helpNav = unFlatten(helpNavItems);

export const helpNodeById = (id: number, navNode: HelpNodeInterface[] = helpNav): any => {
  for (let i = 0; i < navNode.length; i++) {
    const node = navNode[i];
    if (node.id === id) {
      return node;
    } else {
      if (node.children.length) {
        return helpNodeById(id, node.children);
      }
    }
  }
};

export const helpNodeIdFromRouteName = (route: Route): number => {
  let node = 1;
  switch (route.name) {
    case 'Home':
      node = helpNodes.home;
      break;
    case 'CellChooser':
      node =  helpNodes.cellChooser;
      break;
    case 'DeliveryCode':
      node =  helpNodes.deliveryCode;
      break;
    case 'DeliveryDone':
      node =  helpNodes.deliveryDone;
      break;
    case 'Pickup':
      node =  helpNodes.pickup;
      break;
    default:
      node =  helpNodes.home;
      break;
  }
  return node;
};

export const getHelpNode = (route: Route): any => {
  return helpNodeById(helpNodeIdFromRouteName(route));
};
