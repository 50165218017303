












import { Component, Vue } from 'vue-property-decorator';
import {IOnOffHandlers} from '@/models/IOnOffHandlers';

@Component
export default class Loading extends Vue {
  private isLoading = false;
  protected subscribes: IOnOffHandlers = {};

  created(): void {

    this.subscribes = this.$ons({
      'backend.call.begin': this.startLoading,
      'backend.call.OK': this.stopLoading,
      'backend.call.KO': this.stopLoading,
    });

  }
  beforeDestroy(): void {
    this.$offs(this.subscribes);
  }

  startLoading() {
    this.isLoading = true;
  };
  stopLoading() {
    this.isLoading = false;
  };
}
