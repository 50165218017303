


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavModule extends Vue {
  @Prop({default: true})
  nextDisabled!: boolean;
}
