import {Commit, Dispatch} from 'vuex';
import axios from 'axios';
import {IErrors} from '@/models/IErrors';
import {apiEndpoint} from '@/helpers/endpoints';
import responseHandler from '@/plugins/responseHandler';

interface IShipment {
  hubId: number|null;
  cellSizeId: number|null;
  deliveryCode: string;
  errors: IErrors;
}

interface ActionInterface {
  state: IShipment;
  commit: Commit;
  dispatch: Dispatch;
}

const initialState = (): IShipment => {
  return {
    hubId: null,
    cellSizeId: null,
    deliveryCode: '',
    errors: {},
  }
};

const getters = {
  shipment: (state: IShipment): IShipment => {
    return state;
  },
  errors: (state: IShipment) => state.errors,
};

const actions = {

  setHubId({commit}: ActionInterface, hubId: number) {
    commit('setHubId', hubId);
  },
  setCellSizeId({commit}: ActionInterface, cellSizeId: number) {
    commit('setCellSizeId', cellSizeId);
  },
  setDeliveryCode({commit}: ActionInterface, deliveryCode: string) {
    commit('setDeliveryCode', deliveryCode);
  },
  reset({commit}: ActionInterface) {
    commit('reset');
  },
  createShipment: ({ commit, state }: ActionInterface): any => responseHandler.handle(
      () => axios({
        url: apiEndpoint() + 'create',
        method: 'POST',
        data: {
          'public_id': state.hubId,
          'cell_size_id': state.cellSizeId,
          'delivery_token': state.deliveryCode,
        }
      }),
      commit,
      'shipmentSent'
  ),
  errors: ({commit}: ActionInterface, errors: IErrors): any => {
    commit('errors', errors);
  }
};

const mutations = {

  shipmentSent(state: IShipment, payload: any) {
    state = Object.assign(state, payload);
  },

  setHubId(state: IShipment, hubId: number) {
    state.hubId = hubId;
  },

  setCellSizeId(state: IShipment, cellSizeId: number) {
    state.cellSizeId = cellSizeId;
  },

  setDeliveryCode(state: IShipment, deliveryCode: string) {
    state.deliveryCode = deliveryCode;
  },

  reset(state: IShipment) {
    Object.assign(state, initialState());
  },
  errors(state: IShipment, payload: IErrors) {
    state.errors = payload;
  },

};


export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
