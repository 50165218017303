const messages = {
  common: {
    ok: 'Fet',
    next: 'Següent',
    close: 'Tanca',
    back: 'Tornar',
  },
  home: {
    deliveryButton: 'Lliurament',
    pickupButton: 'Recollida',
    expiredSession: {
      title: 'La sessió ha expirat',
      description: 'Si desitja entrar escanegi novament el codi QR situat al Smart Point',
    },
  },

  cellChooser: {
    title: 'Tamany del compartiment',
    description: 'Selecciona el tipus de compartiment més adequat per deixar el paquet segons la seva grandària',
  },
  cellSizes: {
    medium: {
      name: 'Mitjà',
      description: '',
      sizes: '45 x 21 x 60cm',
    },
    big: {
      name: 'Gran',
      description: '',
      sizes: '45 x 32 x 60cm',
    },
    small: {
      name: 'Petit',
      description: '',
      sizes: '45 x 10 x 60cm',
    }
  },
  deliveryCode: {
    title: 'Códi de lliurament',
    addressExample: '<strong>Joan Vila</strong><br>' +
        '<span class="text-thin">Adreça lliurament:</span><br> ' +
        '<strong class="code">ZR04S3</strong> Códi lliurament<br>' +
        'C/D\'Exemple 123<br> ' +
        '08980 La Meva Ciutat',
  },
  deliveryDone: {
    title: 'Ja pots deixar el paquet',
    text: 'Introdueix el paquet en el compartiment que s\'ha obert',
    fail: {
      title: 'No s\'ha obert cap compartiment?',
      text: 'Si per determinades circumstàncies el compartiment NO S\'HA OBERT, <strong> prem el següent botó per tornar a intentar la seva obertura.</strong>',
      button: 'Torna a provar',
    }
  },
  help: {
    home: {
      title: 'Necessites ajuda?',
      shortText: '',
      text: '<p>Si et trobes en dificultats truca\'ns a el nombre que hi ha aquí sota per ser atès pel nostre personal, tot i que potser t\'interessi la nostra ajuda en línia :)</p>',
    },
    delivery: {
      title: 'Ajuda a repartidors',
      shortText: 'Instruccions per lliurar un paquet',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur consequuntur cupiditate dolorem eligendi, nemo nulla quo repudiandae ullam! Aperiam eius ex iste modi porro veniam. Eaque incidunt maxime nesciunt quod.</p>',
    },
    pickup: {
      title: 'Ajuda per recollida',
      shortText: 'Instruccions per recollir un paquet',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi non optio sint. Autem consectetur culpa distinctio dolor earum, ipsa libero nulla porro possimus praesentium quaerat quam qui quidem, unde voluptatem.</p>',
    },
    cellChooser: {
      title: '¿Que compartimento escoger?',
      shortText: 'Notas a tener en cuenta para escoger compartimento',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam beatae commodi consequatur, cum molestias mollitia nam sapiente tempora tenetur? Magnam maiores officiis sit. Aperiam ex nostrum odit tenetur vitae.</p>',
    },
    deliveryCode: {
      title: 'Introducir el código de entrega',
      shortText: '¿Tienes algún problema con el código proporcionado?',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam asperiores, beatae culpa cumque dolor eaque explicabo magnam, natus nemo nihil officia qui recusandae reprehenderit unde voluptas voluptatem voluptatum. Provident, repellendus!</p>',
    },
    deliveryDone: {
      title: 'Introducir el paquete en la celda',
      shortText: 'Preguntas y respuestas rápidas',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad amet blanditiis consequatur, cum cupiditate debitis delectus deserunt eveniet facere harum ipsa ipsum iste nobis nostrum officia omnis quo repellendus vitae!</p>',
    },
  },

  errors: {
    shipmentSent: {
      delivery_token: {
        // example: this message will have precedence over errors.general.general.required and used automatically with the 'shipmentSent' mutation
        required: 'Necessitem el codi de lliurament'
      },
      cell_size_id: {
        required: 'Falta per seleccionar el compartiment apropiat',
      },
      _: {
        noUser: 'No s\'ha trobat cap destinatari amb el codi de lluirament introdüit',
        noCell: '<h3>Està ple</h3><p>L\'Smart Point té tots els compartiments plens</p>',
        noHub: 'La connexió amb el hub no és correcta. Tanca aquesta pàgina i torna a entrar escanejant el codi QR que apareix a l\'Smart Point.',
        cellWontOpen: '¡Ups! Sembla que s\'ha perdut la connexió amb l\'Smart Point. Espera uns segons i torna a intentar-ho.',
      },
    },
    getSettings: {
      _: {
        '404': 'La connexió amb el hub no és correcta. Tanca aquesta pàgina i torna a entrar escanejant el codi QR que apareix a l\'Smart Point.',
      }
    },
    general: {
      // this will be used when the 'required' error happens on a field called 'name'
      // name: {
      //   required: 'Necesitamos el nombre',
      // }
      general: {
        // example: translation for laravel 'required' validation rule which will be used for all fields
        // required: 'Necesitamos este campo',
      },
      unknown: 'S\'ha prodüit un error inesperat',
    },
  }
};
export default messages;
