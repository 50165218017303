const enum EnvironmentTypes {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export const appVersion = process.env.PACKAGE_VERSION || '0';

export default {
  isProduction: () => process.env.NODE_ENV === EnvironmentTypes.PRODUCTION,
  isStaging: () => process.env.NODE_ENV === EnvironmentTypes.STAGING,
  isDev: () => process.env.NODE_ENV === EnvironmentTypes.DEVELOPMENT,
};
