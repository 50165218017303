














import {Vue, Component, Watch, Prop} from 'vue-property-decorator';

@Component
export default class Dialog extends Vue {

  private showDialog = false;
  private showDialogOverlay = false;
  private showDialogBox = false;
  private autoClose = 0;

  @Prop({default: 500, type: Number})
  private width!: number;

  @Prop({ default: false})
  private show?: boolean;

  get dialogBoxStyle() {
    return 'width: '+ this.width + 'px';
  }

  @Watch('show')
  toggleVisibility() {
    if (this.show) {
      this.onOpen();
    } else {
      this.onClose();
    }
  }

  onClose() {
    clearInterval(this.autoClose);
    this.showDialogBox = false;
    this.showDialogOverlay = false;
    setTimeout(() => { this.showDialog = false }, 400);
  }

  onOpen() {
    this.showDialog = true;
    setTimeout(() => { this.showDialogOverlay = true }, 1);
    setTimeout(() => { this.showDialogBox = true }, 100);
    //this.autoClose = setInterval(() => { this.onClose() }, 3000);
  }

}
