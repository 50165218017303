































import { Component, Prop, Vue } from 'vue-property-decorator';
import {HelpNavItemInterface, helpNodeIdFromRouteName} from '@/helpers/helpNav';

@Component
export default class HelpContent extends Vue {
  @Prop()
  private item!: HelpNavItemInterface;

  get showBackButton() {
    return this.item.parent > 0 &&
        this.item.id !== helpNodeIdFromRouteName(this.$route);
  }
}
