










































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import NavModule from '@/components/NavModule.vue';
import {AvailableCellInterface} from '@/store/modules/Settings';

const shipmentModule = namespace('shipment');
const settingsModule = namespace('settings');

@Component({
  components: {
    NavModule,
  }
})
export default class CellChooser extends Vue {

  private cellSizeSelected: number|null = null;

  @shipmentModule.Action('setCellSizeId')
  private setCellSizeId!: (id: number) => void;

  @settingsModule.Getter('availableCells')
  private availableCells!: () => AvailableCellInterface[];

  selectSize(val: number) {
    this.cellSizeSelected = val;
    this.setCellSizeId(val);
  }

  onBack() {
    this.$router.push({ name: 'Home'});
  }
  onNext() {
    this.$router.push({ name: 'DeliveryCode'});
  }
}
