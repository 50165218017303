




import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {IOnOffHandlers} from '@/models/IOnOffHandlers';
import {IErrors} from '@/models/IErrors';
import {uiBus} from '@/plugins/uiBus';

const settingsModule = namespace('settings');

@Component
export default class Entrance extends Vue {

  protected subscribes: IOnOffHandlers = {};

  @settingsModule.Action('setHubId')
  private setHubId!: (id: string) => void;

  @settingsModule.Action('getSettings')
  private getSettings!: () => void;

  @settingsModule.Getter('errors')
  private errors!: IErrors;

  created(): void {

    this.subscribes = this.$ons({
      'backend.call.OK.getSettings': this.nextScreen,
      'backend.call.KO.getSettings': this.showErrors,
    });

    if (this.$route.params.id) {
      const hubId = '' + this.$route.params.id;
      this.setHubId(hubId);
      this.getSettings();
    }
  }

  private nextScreen() {
    this.$router.push({name: 'Home'});
  }
  private showErrors() {
    uiBus.emit('dialog:open', this.errors);
    this.setHubId('');
    this.$router.push({name: 'Home'});
  }
}
