






















import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {setLocale, getLocale} from '@/services/translations';
import {IOnOffHandlers} from '@/models/IOnOffHandlers';
import {ISettings} from '@/store/modules/Settings';
const settingsModule = namespace('settings');

@Component
export default class LanguageChooser extends Vue {

  private showLanguages = false;
  private showLanguagesOverlay = false;
  private showLanguagesContent = false;
  private autoClose = 0;

  protected subscribes: IOnOffHandlers = {};

  @settingsModule.Getter('settings')
  private settings!: ISettings;

  get locale(): string {
    return getLocale();
  }

  setLanguage(locale: string) {
    setLocale(locale);
    setTimeout(() => { this.onClose() }, 400);
  }

  created(): void {
    this.subscribes = this.$ons({
      'languages:open': this.onOpen,
      'languages:close': this.onClose,
    })
  }

  onOpen() {
    this.showLanguages = true;
    setTimeout(() => { this.showLanguagesOverlay = true }, 1);
    setTimeout(() => { this.showLanguagesContent = true }, 100);
    this.autoClose = setInterval(() => { this.onClose() }, 3000);
  }

  onClose() {
    clearInterval(this.autoClose);
    this.showLanguagesContent = false;
    this.showLanguagesOverlay = false;
    setTimeout(() => { this.showLanguages = false }, 400);
  }
}
