import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/locale';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'es',
  messages,
});

export const setLocale = (lang: string) => {
  i18n.locale = lang;
};

export const getLocale = (): string => i18n.locale;
