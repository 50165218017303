import Vue from 'vue';
import Vuex from 'vuex';
import SettingsModule from '@/store/modules/Settings';
import ShipmentModule from '@/store/modules/Shipment';

Vue.use(Vuex);

const modules = {
  shipment: ShipmentModule,
  settings: SettingsModule,
};

export default new Vuex.Store({
  modules,
});
