import { LocaleMessages } from 'vue-i18n';
import es from './es';
import ca from './ca';
import en from './en';

const messages: LocaleMessages = {
  es,
  ca,
  en,
};

export default messages;
