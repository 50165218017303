
























import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {getLocale} from '@/services/translations';
import {uiBus} from '@/plugins/uiBus';
import {ISettings} from '@/store/modules/Settings';
const settingsModule = namespace('settings');

@Component
export default class AppFooter extends Vue {

  @settingsModule.Getter('settings')
  private settings!: ISettings;

  get showAddress(): boolean {
    return this.$route.name !== 'Home';
  }

  get locale() {
    return getLocale();
  }
  openHelp() {
    uiBus.emit('help:open');
  }
  openLanguages() {
    uiBus.emit('languages:open');
  }
}
