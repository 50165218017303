




















import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {IOnOffHandlers} from '@/models/IOnOffHandlers';
import {getHelpNode, helpNodeById} from '@/helpers/helpNav';
import HelpContent from '@/components/Help/HelpContent.vue';
import {ISettings} from '@/store/modules/Settings';

const settingsModule = namespace('settings');

@Component({
  components: {
    HelpContent,
  }
})
export default class Help extends Vue {

  private showHelp = false;
  private showHelpOverlay = false;
  private showHelpBox = false;
  private autoClose = 0;
  private helpNavId = 0;
  private nav = [];

  protected subscribes: IOnOffHandlers = {};

  @settingsModule.Getter('settings')
  private settings!: ISettings;

  created(): void {
    this.subscribes = this.$ons({
      'help:open': this.onOpen,
      'help:close': this.onClose,
    })
  }

  onOpen() {
    this.nav = getHelpNode(this.$route);
    this.showHelp = true;
    setTimeout(() => { this.showHelpOverlay = true }, 1);
    setTimeout(() => { this.showHelpBox = true }, 100);
    this.autoClose = setInterval(() => { this.onClose() }, 60000);
  }

  onClose() {
    clearInterval(this.autoClose);
    this.showHelpBox = false;
    this.showHelpOverlay = false;
    setTimeout(() => { this.showHelp = false }, 400);
  }
  onPhoneCall() {
    const phoneNumber: string = this.settings.contact.phonePrefix.trim() +
        this.settings.contact.phone.replace(/\s/g,'');
    window.open('tel:' + phoneNumber);
  }

  changeHelpNode(id: number): void {
    // Delay interval 1 min more
    clearInterval(this.autoClose);
    this.autoClose = setInterval(() => { this.onClose() }, 60000);
    this.nav = helpNodeById(id);
  }


}
