import {Commit, Dispatch} from 'vuex';
import axios from 'axios';
import {IErrors} from '@/models/IErrors';
import responseHandler from '@/plugins/responseHandler';
import {apiEndpoint} from '@/helpers/endpoints';

export interface ISettings {
  hubId: string;
  name: string;
  description: string;
  location: SettingsLocationInterface;
  contact: SettingsContactInterface;
  languages: SettingsLanguagesInterface[];
  availableCells: AvailableCellInterface[];
  errors: IErrors;
}

interface SettingsContactInterface {
  phonePrefix: string;
  phone: string;
}

interface SettingsLanguagesInterface {
  code: string;
  name: string;
}
export interface AvailableCellInterface {
  cellSizeId: number;
  name: string;
}

interface SettingsLocationInterface {
  address: string;
  town: string;
  postalCode: string;
  countryId: number;
  lat: number;
  long: number;
}

interface ActionInterface {
  state: ISettings;
  commit: Commit;
  dispatch: Dispatch;
}

const initialState = (): ISettings => {
  return {
    hubId: '',
    name: '',
    description: '',
    location: {
      address: '',
      town: '',
      postalCode: '',
      countryId: 0,
      lat: 0,
      long: 0,
    },
    contact: {
      phonePrefix: '+34',
      phone: '93 112 67 57',
    },
    languages: [
      { code: 'es', name: 'Español' },
      { code: 'ca', name: 'Català' },
      { code: 'en', name: 'English' },
    ],
    availableCells: [
      {cellSizeId: 100, name: 'small'},
      {cellSizeId: 200, name: 'medium'},
      {cellSizeId: 300, name: 'big'}
    ],
    errors: {},
  }
};

const getters = {
  settings: (state: ISettings): ISettings => {
    return state;
  },
  availableCells: (state: ISettings): AvailableCellInterface[] => {
    return state.availableCells;
  },
  errors: (state: ISettings) => state.errors,
};

const actions = {

  reset({commit}: ActionInterface) {
    commit('reset');
  },
  getSettings: ({ commit, state }: ActionInterface): any => responseHandler.handle(
      () => axios({
        url: apiEndpoint() + 'me/' + state.hubId,
        method: 'GET',
      }),
      commit,
      'getSettings',
  ),
  setHubId: ({commit }: ActionInterface, id: number) => {
    commit('setHubId', id);
  },

  errors: ({commit}: ActionInterface, errors: IErrors): any => {
    commit('errors', errors);
  }
};

const mutations = {
  getSettings(state: ISettings, payload: any) {
    state.name = payload.name;
    state.description = payload.description;
    state.location.address = payload.address;
    state.location.lat = payload.geo_lat;
    state.location.long = payload.geo_long;
  },
  setHubId(state: ISettings, id: string) {
    state.hubId = id;
  },
  reset(state: ISettings) {
    Object.assign(state, initialState());
  },
  errors(state: ISettings, payload: IErrors) {
    state.errors = payload;
  },

};


export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
