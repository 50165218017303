const messages = {
  common: {
    ok: 'Hecho',
    next: 'Siguiente',
    close: 'Cerrar',
    back: 'Retroceder',
  },
  home: {
    deliveryButton: 'Entrega',
    pickupButton: 'Recogida',
    expiredSession: {
      title: 'La sesión ha caducado',
      description: 'Si desea entrar escanea de nuevo el código QR situado en el Smart Point',
    },
  },
  cellChooser: {
    title: 'Tamaño del compartimento',
    description: 'Selecciona el tipo de compartimento más adecuado para dejar el paquete según su tamaño',
  },
  cellSizes: {
    medium: {
      name: 'Mediano',
      description: '',
      sizes: '45 x 21 x 60cm',
    },
    big: {
      name: 'Grande',
      description: '',
      sizes: '45 x 32 x 60cm',
    },
    small: {
      name: 'Pequeño',
      description: '',
      sizes: '45 x 10 x 60cm',
    }
  },
  deliveryCode: {
    title: 'Código de entrega',
    addressExample: '<strong>Juan Díaz</strong><br>' +
        '<span class="text-thin">Dirección entrega:</span><br>' +
        '<strong class="code">ZR04S3</strong> Código entrega<br>' +
        'C/De ejemplo 123<br>' +
        '08980 MiCiudad',
  },
  deliveryDone: {
    title: 'Ya puedes dejar el paquete',
    text: 'Introduce el paquete en el compartimento que se ha abierto',
    fail: {
      title: '¿No se ha abierto ningún compartimento?',
      text: 'Si por determinadas circunstancias el compartimento NO SE HA ABIERTO, <strong>pulsa el siguiente botón para reintentar su apertura.</strong>',
      button: 'Reintentar',
    }
  },
  help: {
    home: {
      title: '¿Necesitas ayuda?',
      shortText: '',
      text: '<p>Si te encuentras en apuros llámanos al número que hay aquí abajo para ser atendido por nuestro personal, aunque quizás te interese nuestra ayuda en pantalla :)</p>',
    },
    delivery: {
      title: 'Ayuda a repartidores',
      shortText: 'Instrucciones para entregar un paquete',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur consequuntur cupiditate dolorem eligendi, nemo nulla quo repudiandae ullam! Aperiam eius ex iste modi porro veniam. Eaque incidunt maxime nesciunt quod.</p>',
    },
    pickup: {
      title: 'Ayuda para recogida',
      shortText: 'Instrucciones para recoger un paquete',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi non optio sint. Autem consectetur culpa distinctio dolor earum, ipsa libero nulla porro possimus praesentium quaerat quam qui quidem, unde voluptatem.</p>',
    },
    cellChooser: {
      title: '¿Que compartimento escoger?',
      shortText: 'Notas a tener en cuenta para escoger compartimento',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam beatae commodi consequatur, cum molestias mollitia nam sapiente tempora tenetur? Magnam maiores officiis sit. Aperiam ex nostrum odit tenetur vitae.</p>',
    },
    deliveryCode: {
      title: 'Introducir el código de entrega',
      shortText: '¿Tienes algún problema con el código proporcionado?',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam asperiores, beatae culpa cumque dolor eaque explicabo magnam, natus nemo nihil officia qui recusandae reprehenderit unde voluptas voluptatem voluptatum. Provident, repellendus!</p>',
    },
    deliveryDone: {
      title: 'Introducir el paquete en la celda',
      shortText: 'Preguntas y respuestas rápidas',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad amet blanditiis consequatur, cum cupiditate debitis delectus deserunt eveniet facere harum ipsa ipsum iste nobis nostrum officia omnis quo repellendus vitae!</p>',
    }
  },

  errors: {
    shipmentSent: {
      delivery_token: {
        // example: this message will have precedence over errors.general.general.required and used automatically with the 'shipmentSent' mutation
        required: 'Necesitamos el token'
      },
      cell_size_id: {
        required: 'Falta por seleccionar el compartimento apropiado',
      },
      _: {
        noUser: 'No se ha encontrado ningún destinatario con el código de entrega introducido',
        noCell: '<h3>Ocupado</h3><p>El Smart Point tiene todos los compartimentos ocupados</p>',
        noHub: 'La conexión con el hub no es correcta. Cierra esta página y vuelve a entrar escaneando el código QR que aparece en el Smart Point.',
        cellWontOpen: '¡Ups! Parece que se ha perdido la conexión con el Smart Point. Espera unos segundos y vuelve a intentarlo.',
      },
    },
    getSettings: {
      _: {
        '404': 'La conexión con el hub no es correcta. Cierra esta página y vuelve a entrar escaneando el código QR que aparece en el Smart Point.',
      }
    },
    general: {
      // this will be used when the 'required' error happens on a field called 'name'
      // name: {
      //   required: 'Necesitamos el nombre',
      // }
      general: {
        // example: translation for laravel 'required' validation rule which will be used for all fields
        // required: 'Necesitamos este campo',
      },
      unknown: 'Se ha producido un error inesperado',
    },
  }
};
export default messages;
