const messages = {
  common: {
    ok: 'Ok',
    next: 'Next',
    close: 'Close',
    back: 'Back',
  },
  home: {
    deliveryButton: 'Deliver',
    pickupButton: 'Pick Up',
    expiredSession: {
      title: 'The session has expired',
      description: 'If you want to enter, scan the QR code located on the Smart Point again',
    },
  },
  cellChooser: {
    title: 'Compartment size',
    description: 'Select the most suitable type of compartment to leave the package according to its size',
  },
  cellSizes: {
    medium: {
      name: 'Medium',
      description: '',
      sizes: '45 x 21 x 60cm',
    },
    big: {
      name: 'Big',
      description: '',
      sizes: '45 x 32 x 60cm',
    },
    small: {
      name: 'Small',
      description: '',
      sizes: '45 x 10 x 60cm',
    }
  },
  deliveryCode: {
    title: 'Delivery code',
    addressExample: '<strong>John Doe</strong><br>' +
        '<span class="text-thin">Delivery address:</span><br>' +
        '<strong class="code">ZR04S3</strong> Delivery code<br>' +
        'Example St. 123<br>' +
        'Z8980 My City',
  },
  deliveryDone: {
    title: 'You can leave the package',
    text: 'Insert the package into the compartment that has been opened',
    fail: {
      title: 'No compartment has been opened?',
      text: 'If due to certain circumstances the compartment HAS NOT BEEN OPENED, <strong> press the following button to retry its opening.</strong>',
      button: 'Retry',
    }
  },
  help: {
    home: {
      title: 'Need help?',
      shortText: '',
      text: '<p>If you find yourself in trouble, call us at the number below to be assisted by our staff, although you may be interested in our online help :)</p>',
    },
    delivery: {
      title: 'Help for delivery',
      shortText: 'Instructions for delivering a package',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur consequuntur cupiditate dolorem eligendi, nemo nulla quo repudiandae ullam! Aperiam eius ex iste modi porro veniam. Eaque incidunt maxime nesciunt quod.</p>',
    },
    pickup: {
      title: 'Help for pickup',
      shortText: 'Instrucciones para recoger un paquete',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi non optio sint. Autem consectetur culpa distinctio dolor earum, ipsa libero nulla porro possimus praesentium quaerat quam qui quidem, unde voluptatem.</p>',
    },
    cellChooser: {
      title: '¿Que compartimento escoger?',
      shortText: 'Notas a tener en cuenta para escoger compartimento',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam beatae commodi consequatur, cum molestias mollitia nam sapiente tempora tenetur? Magnam maiores officiis sit. Aperiam ex nostrum odit tenetur vitae.</p>',
    },
    deliveryCode: {
      title: 'Introducir el código de entrega',
      shortText: '¿Tienes algún problema con el código proporcionado?',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam asperiores, beatae culpa cumque dolor eaque explicabo magnam, natus nemo nihil officia qui recusandae reprehenderit unde voluptas voluptatem voluptatum. Provident, repellendus!</p>',
    },
    deliveryDone: {
      title: 'Introducir el paquete en la celda',
      shortText: 'Preguntas y respuestas rápidas',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad amet blanditiis consequatur, cum cupiditate debitis delectus deserunt eveniet facere harum ipsa ipsum iste nobis nostrum officia omnis quo repellendus vitae!</p>',
    },
  },

  errors: {
    shipmentSent: {
      delivery_token: {
        // example: this message will have precedence over errors.general.general.required and used automatically with the 'shipmentSent' mutation
        required: 'We need a delivery code'
      },
      cell_size_id: {
        required: 'Please, select the appropriate compartment',
      },
      _: {
        noUser: 'No recipient found with the delivery code entered',
        noCell: '<h3>It\'s busy</h3><p>The Smart Point has all compartments occupied</p>',
        noHub: 'The connection to the hub is not correct. Close this page and re-enter by scanning the QR code that appears on the Smart Point.',
        cellWontOpen: 'Whoops! It seems that the connection to the Smart Point has been lost. Please wait a few seconds and try again.',
      },
    },
    getSettings: {
      _: {
        '404': 'The connection to the hub is not correct. Close this page and re-enter by scanning the QR code that appears on the Smart Point.',
      }
    },
    general: {
      // this will be used when the 'required' error happens on a field called 'name'
      // name: {
      //   required: 'Necesitamos el nombre',
      // }
      general: {
        // example: translation for laravel 'required' validation rule which will be used for all fields
        // required: 'Necesitamos este campo',
      },
      unknown: 'Unexpected error has ocurred',
    },
  }
};
export default messages;
